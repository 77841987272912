import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { ethers } from "ethers";
import '@rainbow-me/rainbowkit/styles.css';

import Connect from '../connect';

// define nav items and drawer width
const navItems = ['Home'];
const drawerWidth = 240;

// const mumbai = {
//   id: 80001,
//   name: 'Mumbai Testnet',
//   network: 'avalanche',
//   iconUrl: 'https://example.com/icon.svg',
//   iconBackground: '#fff',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'MATIC',
//     symbol: 'MATIC',
//   },
//   rpcUrls: {
//     public: { http: ['https://api.zan.top/node/v1/polygon/mumbai/public'] },
//     default: { http: ['https://rpc-mumbai.maticvigil.com'] },
//   },
//   blockExplorers: {
//     default: { name: 'polygonscan', url: 'https://mumbai.polygonscan.com/' },
//     etherscan: { name: 'polygonscan', url: 'https://mumbai.polygonscan.com/' },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xca11bde05977b3631167028862be2a173976ca11',
//       blockCreated: 11907934,
//     },
//   },
//   testnet: true,
// };

// const { chains, publicClient } = configureChains(
//   [mumbai,sepolia ],
//   [
//     alchemyProvider({ apiKey: "6EDoKYlQPVkeYGzQh79M4SUGnV2T3Hre" }),
//     publicProvider()
//   ]
// );

// const { connectors } = getDefaultWallets({
//   appName: 'VStack',
//   projectId: '46e21298267616c47f8df51dc89ae158',
//   chains
// });

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient
// })

const handleNav = (item) => () => {
  if (item === 'Home') {
    window.location.href = '/';
  }
  console.log(item);
}

export default function NavBar(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // define drawer toggle
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  // define drawer content
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        VStack
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding onClick={handleNav(item)}>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = typeof document !== 'undefined' ? document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* coontent in app bar */}

      <AppBar component="nav" >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flewGrow: 1, display: { xs: 'none', sm: 'block' }, alignItems: 'center' }}
          >
            VStack
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, alignItems: 'center' }}>
            {navItems.map((item) => (
              <Button key={item} onClick={handleNav(item)} sx={{ color: '#fff' }}>
                {item}
              </Button>
            ))}
          </Box>

          <Box sx={{ display: { sm: 'block' }, right: "10px", position: "absolute" }}>
              <Connect />
          </Box>

        </Toolbar>
      </AppBar>
      {/* mobile view drawer */}
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </Box>
  )
}