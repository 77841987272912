import * as React from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import StorageIcon from '@mui/icons-material/Storage';
import DatasetIcon from '@mui/icons-material/Dataset';
import DescriptionIcon from '@mui/icons-material/Description';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Link, useHistory } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { MuiFileInput } from 'mui-file-input';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { ethers } from "ethers";
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useSignMessage } from 'wagmi'
import NFTContractABI from '../../contracts/NFTContractABI.json';
import VstackVideoNFT from '../../contracts/VstackVideoNFTABI.json';
import { joinSignature } from 'ethers/lib/utils';
// Video display gallery content
const tokenIds = ["107038878564611884872404029959327869320500627559997838051688489164377848425897",
  "53540915285714654845593774652743252994385995620332685529988066067756045184350",
  "1438597254209697238080372026422006727695872929751432101585038492474299703456",
  "28530582623913533355329822285427913151070341163466651922373992338767108531286",
  "38802145109174233202879894671531355764665972093157457848103059437219865967674",
  "41217566041813812017776911768917036620914472949830723776132630682023902087175"];

const resolutions = [480, 720, 1080, '2k', '4k'];

// Popup Modal style
const style = {
  position: 'absolute',
  top: { xs: '35%', sm: '40%' },  
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: {
    xs: 2,   
    sm: 4,
  },
  mt: 8,
  display: 'flex',
  flexDirection: 'column',
  width: {    
    xs: '90%',    
    sm: '60%',    
    md: '50%'
  },
  maxWidth: '600px',    
  overflowY: 'auto',
  maxHeight: '80vh'   
};

// Key Features content
const tiers = [
  {
    title: 'Global Storage, Instant Stream',
    description: [
      'With VStack\'s decentralized storage network and global CDN, your videos are not just secure, but stream-ready wherever your audience is.'
    ],
    iconVariant: 'StorageIcon'
  },
  {
    title: 'Your Vision, Now in Web3',
    description: [
      'Creating a Web3 video has never been easier. Upload your content, and let VStack handle the rest. From transcoding to streaming, we\'ve got you covered.',
    ],
    iconVariant: 'DatasetIcon'
  },
  {
    title: 'Native NFT, From Video to Value',
    description: [
      "Turn your videos into valuable assets with our native NFT minting feature. It's not just a video; it's an investment in your creative future.",
    ],
    iconVariant: 'DescriptionIcon'
  },
];

// 
export default function Album(props) {
  // Content in Popup Modal
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const [uploadStatus, setUploadStatus] = React.useState(null);
  const [video, setVideo] = React.useState(null);
  const [cover, setCover] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverStatus, setServerStatus] = React.useState(null);
  const [progress, setProgress] = React.useState(1);
  const [uploadBuffer, setUploadBuffer] = React.useState(0);
  const [buffer, setBuffer] = React.useState(0);
  const [taskCompleted, setTaskCompleted] = React.useState(false);
  const [showUserVideo, setShowUserVideo] = React.useState(false);
  const [UserVideoURl, setUserVideoURL] = React.useState(null);
  const [UserVideo, setUserVideo] = React.useState(null);
  const [allVideo, setAllVideo] = React.useState(null);
  const [resolution, setResolution] = React.useState([]);

  const { openConnectModal } = useConnectModal();
  const wagmiWallet = useAccount();

  const wagmiSigner = useSignMessage({
    onSuccess: (data, variables) => {
      console.log("Signed Message: " + data)
      const address = ethers.utils.verifyMessage(variables.message, data);
      console.log("User address from signMessage: " + address)
    },
    onError: (error, variables) => {
      console.error("Failed to sign from signMessage:", error);
    },
    onMutate: (variables) => {
      console.log("Signing message:", variables.message);
    },
    onSettled: (data, error, variables) => {
      console.log("Finished signing message:", variables);
    }
  });
  const handleOpen = () => {
    if (openConnectModal) {
      openConnectModal();
      return;
    }
    setOpen(true);
  }

  const handleVideo = (newFile) => {
    if (!newFile) { setVideo(null); return; }
    const reader = new FileReader();
    reader.onload = function (event) {
      const fileData = new Uint8Array(event.target.result);
      console.log("Video file data:", fileData);
      if (fileData.length > 255720070) {
        alert("Video file size is too large, please upload a video file less than 256 MB")
        return
      }
      const fileHash = ethers.utils.keccak256(fileData);
      console.log("Video keccak256 Hash:", fileHash);

      setVideo({
        file: newFile,
        hash: fileHash
      });
      reader.abort();
    };
    reader.readAsArrayBuffer(newFile);
  };

  const handleCover = (newFile) => {
    if (!newFile) { setCover(null); return; }
    const reader = new FileReader();
    reader.onload = function (event) {
      const fileData = new Uint8Array(event.target.result);
      console.log("Cover file data:", fileData);
      const fileHash = ethers.utils.keccak256(fileData);
      console.log("Cover keccak256 Hash:", fileHash);
      setCover({
        file: newFile,
        hash: fileHash
      });
      reader.abort();
    };
    reader.readAsArrayBuffer(newFile);
  }

  const handleUpload = async (event) => {
    if (!video || !cover) {
      alert("Please upload video and cover image first!")
      return
    }
    setTaskCompleted(false);
    setServerStatus(null);
    const data = new FormData(event.currentTarget);
    event.preventDefault();
    const formDataBody = new FormData();
    setIsLoading(true);

    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const provider = new ethers.providers.JsonRpcProvider();
    // const signer = provider.getSigner();
    const address = wagmiWallet.address;
    console.log("User address: " + address)

    formDataBody.append('video', video.file);
    formDataBody.append('cover', cover.file);
    formDataBody.append('index_server_endpoint', data.get('indexer'));
    formDataBody.append('provider_endpoint', data.get('provider'));
    formDataBody.append('title', data.get('title'));
    formDataBody.append('summary', data.get('summary'));

    var resolutionArray = []
    resolution.forEach((item) => {
      formDataBody.append('predefined_scales', item);
      resolutionArray.push(item.toString());
    })

    console.log("predefined_scales", JSON.stringify(resolutionArray));

    // Calculate total hash of user uploaded data
    const titleHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(data.get('title')));
    const summaryHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(data.get('summary')));
    const resolutionsHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(JSON.stringify(resolutionArray)));

    const titleHashBytes = ethers.utils.arrayify(titleHash);
    const summaryHashBytes = ethers.utils.arrayify(summaryHash);
    const resolutionsHashBytes = ethers.utils.arrayify(resolutionsHash);
    const addressBytes = ethers.utils.arrayify(address);
    const seq = ethers.utils.toUtf8Bytes("|")
    const videoHashBytes = ethers.utils.arrayify(video.hash);
    const coverHashBytes = ethers.utils.arrayify(cover.hash);

    console.log("Title Hash is: ", titleHash, titleHashBytes)
    console.log("Summary Hash is: ", summaryHash, summaryHashBytes)
    console.log("Resolutions Hash is: ", resolutionsHash, resolutionsHashBytes)
    console.log("Address Hash is: ", address, addressBytes)
    console.log("Video Hash is: ", video.hash, videoHashBytes)
    console.log("Cover Hash is: ", cover.hash, coverHashBytes)

    const totleHashBytes = ethers.utils.concat([videoHashBytes, seq, coverHashBytes, seq, titleHashBytes, seq, summaryHashBytes, seq, resolutionsHashBytes, seq, addressBytes])
    const totleHash = ethers.utils.keccak256(totleHashBytes)
    console.log("Total Hash is: ", totleHash)

    let signature = null;
    try {
      // signature = await signer.signMessage(totleHash);
      signature = await wagmiSigner.signMessageAsync({ message: totleHash });
      console.log("User signature:", signature);
    } catch (error) {
      console.error("Failed to sign:", error);
      setIsLoading(false);
      return;
    }

    formDataBody.append('signature', signature);
    formDataBody.append('total_hash', totleHash)

    const xhr = new XMLHttpRequest();

    // Update progress (can be used to show progress indicator)
    let lastBufferedPercent = 0; // Add this variable to only update UI when buffered percent increases by 10%
    xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
            const percentComplete = Math.floor((event.loaded / event.total) * 100);
            if (percentComplete >= lastBufferedPercent + 10) {
                console.log(`Upload progress: ${percentComplete}%`);
                setUploadBuffer(percentComplete);
                lastBufferedPercent = percentComplete; 
            }
        }
    };
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          const data = JSON.parse(xhr.responseText);
          console.log(data);
          setUploadStatus(data.upload_state);
          setIsLoading(false);
        } else {
          console.error('Error during the request', xhr.statusText);
          setIsLoading(false);
        };
      }
    };

    xhr.open('POST', 'https://transcoder.vstack.one/api/v1/demo/task', true);
    xhr.setRequestHeader('Accept', 'application/json');

    xhr.send(formDataBody);
  };

  const handleResolutionChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    setResolution(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  function formDataToString(formData) {
    let str = "";
    for (const [key, value] of formData.entries()) {
      str += key + value;
    }
    return str;
  }

  function getAvatarByVariant(iconVariant) {
    switch (iconVariant) {
      case 'StorageIcon':
        return <StorageIcon sx={{ fontSize: 36, marginLeft: 4 }} />;
      case 'DatasetIcon':
        return <DatasetIcon sx={{ fontSize: 36, marginLeft: 4 }} />;
      case 'DescriptionIcon':
        return <DescriptionIcon sx={{ fontSize: 36, marginLeft: 4 }} />;
      default:
        return null;
    }
  }

  const fetchVideoData = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          'accept': 'application/json',
        },
      });
      const data = await response.text();

      const lines = data.split('\n');
      const parsedData = {};

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (line.startsWith('#EXT-X-VERSION')) {
          parsedData.version = line.split(':')[1];
        } else if (line.startsWith('#MEDIA-TOTAL-SIZE')) {
          parsedData.totalSize = line.split(':')[1];
        } else if (line.startsWith('#MEDIA-IDENTIFIER')) {
          parsedData.identifier = line.split(':')[1];
        } else if (line.startsWith('#MEDIA-COVER')) {
          parsedData.cover = "https:" + line.split(':')[2];
        } else if (line.startsWith('#MEDIA-SOURCE')) {
          const encodedSource = line.split(':')[1];
          parsedData.source = atob(encodedSource);
        } else if (line.startsWith('#MEDIA-SOCIAL')) {
          const encodedSource = line.split(':')[1];
          parsedData.social = JSON.parse(atob(encodedSource));
        } else if (line.startsWith('#EXT-X-STREAM-INF')) {
          const streamInfo = line.split(':')[1];
          const streamAttributes = streamInfo.split(',');
          const bandwidth = streamAttributes.find(attr => attr.startsWith('BANDWIDTH=')).split('=')[1];
          const resolution = streamAttributes.find(attr => attr.startsWith('RESOLUTION=')).split('=')[1];
          const frameRate = streamAttributes.find(attr => attr.startsWith('FRAME-RATE=')).split('=')[1];
          const url = lines[i + 1];

          parsedData.streams = parsedData.streams || [];
          parsedData.streams.push({
            bandwidth,
            resolution,
            frameRate,
            url
          });
        }
      }

      return parsedData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  //Check user NFTs
  const getUserNFTs = async () => {
    console.time("getUserNFTs"); // Start timing the entire function
    // if (!window.ethereum) {
    //   console.log("User need to install a web3 wallet first");
    //   return;
    // }

    if (openConnectModal) {
      openConnectModal();
      return;
    }

    console.time("Initialize provider, signer, and contract");
    const provider = new ethers.providers.AlchemyProvider("maticmum", "GGf7o5yESCf-pK-cF3YbhLY5C2ZFNwhD");
    const contractAddress = '0x9f81E1A0A6545cf7d32ac3717D2e59c94e86EA1d';
    const contract = new ethers.Contract(contractAddress, VstackVideoNFT.output.abi, provider);
    console.timeEnd("Initialize provider, signer, and contract");

    console.time("Get user address and token count");
    const userAddress = wagmiWallet.address;
    const tokenCount = await contract.balanceOf(userAddress);
    console.timeEnd("Get user address and token count");

    // Get all token IDs concurrently
    console.time("Get all token IDs");
    const tokenIdsPromises = Array.from({ length: tokenCount.toNumber() }, (_, i) => contract.tokenOfOwnerByIndex(userAddress, i));
    const tokenIds = await Promise.all(tokenIdsPromises);
    console.timeEnd("Get all token IDs");

    // Get all video meta data concurrently
    console.time("Get all video meta data");
    const metaDataPromises = tokenIds.map(tokenId => contract.getVideoMetaData(tokenId));
    const metaDataList = await Promise.all(metaDataPromises);
    console.timeEnd("Get all video meta data");

    // Extract video URLs from meta data and associate each URL with its tokenId
    console.time("Extract video URLs from meta data");
    const videoUrlAndTokenId = metaDataList.map((metaData, index) => {
      let masterHash = metaData[0].toString();
      let decimalTokenId = tokenIds[index].toString(10);
      return {
        url: "https://indexer.vstack.one/api/v1/media/" + masterHash.slice(2) + "?route=auto",
        tokenId: decimalTokenId
      };
    });
    console.timeEnd("Extract video URLs from meta data");

    // Fetch video content concurrently and include tokenId in the content object
    console.time("Fetch video content");
    const videoContentPromises = videoUrlAndTokenId.map(({ url, tokenId }) =>
      fetchVideoData(url).then(data => ({ url, content: data, tokenId })).catch(error => {
        console.error('Error fetching video content:', error);
        return null; // or {} or some other value indicating failure
      })
    );
    console.timeEnd("Fetch video content");

    console.time("Extract video URLs for setUserVideoURL");
    const videoContent = (await Promise.all(videoContentPromises)).filter(Boolean); // filter out any failed fetches
    const videoUrls = videoUrlAndTokenId.map(item => item.url); // just extracting URLs for setUserVideoURL
    console.timeEnd("Extract video URLs for setUserVideoURL");

    setUserVideo(videoContent);
    setUserVideoURL(videoUrls);
    setShowUserVideo(true);
    console.timeEnd("getUserNFTs");
  }

  //get all video
  const getAllVideo = async () => {
    console.time("get All Video");
    // if (!window.ethereum) {
    //   console.log("User need to install a web3 wallet first");
    //   return;
    // }

    const provider = new ethers.providers.AlchemyProvider("maticmum", "GGf7o5yESCf-pK-cF3YbhLY5C2ZFNwhD");
    const contractAddress = '0x9f81E1A0A6545cf7d32ac3717D2e59c94e86EA1d';
    const contract = new ethers.Contract(contractAddress, VstackVideoNFT.output.abi, provider);

    // Get all video meta data concurrently
    const metaDataPromises = tokenIds.map(tokenId => contract.getVideoMetaData(tokenId));
    const metaDataList = await Promise.all(metaDataPromises);

    // Extract video URLs from meta data
    const videoUrl = metaDataList.map((metaData, index) => {
      let masterHash = metaData[0].toString();
      return {
        url: "https://indexer.vstack.one/api/v1/media/" + masterHash.slice(2) + "?route=auto",
        tokenId: tokenIds[index]
      };
    });

    const videoContentPromises = videoUrl.map(({ url, tokenId }) =>
      fetchVideoData(url).then(data => ({ url, content: data, tokenId })).catch(error => {
        console.error('Error fetching video content:', error);
        return null; // or {} or some other value indicating failure
      })
    );

    const videoContent = (await Promise.all(videoContentPromises)).filter(Boolean);
    setAllVideo(videoContent);
    console.timeEnd("get All Video");
  }


  // check video transcoder progress
  const checkUploadStatus = () => {
    if (uploadStatus === null) { console.log("Please upload video first!"); return; }
    let intervalId = setInterval(() => {
      try {
        fetch('https://transcoder.vstack.one/api/v1/task/' + uploadStatus.identifier, {
          headers: {
            'accept': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            console.log(data.transcode_task_state);
            setServerStatus(data.transcode_task_state);
            let currentProgress = 0;
            let currentBuffer = 0;
            if (data.transcode_task_state.index_server_task_state.index_server_report_finished === true) {
              currentProgress += 25;
              currentBuffer += 30;
            }
            if (data.transcode_task_state.provider_upload_task_state.provider_upload_finished === true) {
              currentProgress += 25;
              currentBuffer += 30;
            }
            if (data.transcode_task_state.transcode_task_state.transcode_finished === true) {
              currentProgress += 25;
              currentBuffer += 30;
            }
            if (data.transcode_task_state.transcode_upload_task_state.upload_finished === true) {
              currentProgress += 25;
              currentBuffer += 30;
            }
            if (currentProgress >= 100) {
              setProgress(100);
              setBuffer(100);
              setTaskCompleted(true);
              setUploadStatus(null);
              clearInterval(intervalId); // Stop Fetching
            }
            setProgress(currentProgress);
            setBuffer(currentBuffer);
          })
          .catch(error => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    }, 3000); // Query every 3 seconds

    console.log(uploadStatus);
  }

  const handleOpenPlayer = (url, tokenId) => {
    console.log(url, tokenId);
    let videoUrl = null;
    if (url.startsWith('https://indexer.vstack.one/api/v1/media/')) {
      videoUrl = url.split('/media/').pop();
    } else if (url.startsWith('https://test-streams.mux.dev')) {
      return
    } else {
      videoUrl = url;
    }
    window.location.href = window.location.origin + '/player/' + videoUrl + "&tokenId=" + tokenId;
  };

  React.useEffect(() => {
    setTimeout(() => {
      getAllVideo();
    }, 1000);
  }, []);
  //Album page layout
  return (
    <div className="content-overlay">
      <main>
        <Box
          sx={{
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="#EEEEEE"
              gutterBottom
            >
              VStack Demo
            </Typography>
            <Typography variant="h5" align="center" color="#EEEEEE" paragraph>
              The Ultimate Web3 Video Ecosystem
            </Typography>
            <Typography variant="h5" align="center" color="#EEEEEE" paragraph>
              You're fully secured across the board — whether it's our robust physical streaming network, advanced AI scoring system, developer toolkits, or the creator and viewer experience.
            </Typography>
          </Container>
        </Box>

        <Container maxWidth="lg" component="main">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="#EEEEEE"
            gutterBottom
          >
            Key Features
          </Typography>
          {tiers.map((tier, index) => (
            <Grid container spacing={2} key={index} sx={{ color: "#EEEEEE", padding: 5 }} >
              <Grid md={3}>
                <Item>
                  <Box>
                    {getAvatarByVariant(tier.iconVariant)}
                  </Box>
                </Item>

              </Grid>
              <Grid md={9}>
                <Item>
                  <Typography gutterBottom variant="h4" component="h4" align="left">
                    {tier.title}
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    align="left"
                  >
                    {tier.description}
                  </Typography>

                </Item>
              </Grid>
            </Grid>))}
        </Container>

        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
          xs={12}
          sm={6}
          md={4}
        >
          <Button onClick={handleOpen} variant="contained">Upload Your Video</Button>
          {showUserVideo ? <Button onClick={() => setShowUserVideo(false)} variant="contained">Show All Videos</Button> :
            <Button onClick={getUserNFTs} variant="contained">Check Your Video</Button>}
          {/* <Button onClick={mintNFT} variant="contained">Mint Your Video NFT</Button> */}
        </Stack>

        {/* User Video gallery */}
        {showUserVideo ?
          <Container sx={{ py: 8 }} maxWidth="md">
            <Typography gutterBottom variant="h5" component="h2" sx={{ color: "#EEEEEE" }}>
              User Videos
            </Typography>
            <Grid container spacing={4}>
              {UserVideo.map((video) => (
                <Grid item key={video.url} xs={12} sm={6} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <CardMedia
                      component="div"
                      sx={{
                        // 16:9
                        pt: '56.25%',
                      }}
                      image={video.content.cover}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {video.content.social?.Title}
                      </Typography>
                      <Typography>
                        {video.content.social?.Summary}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => handleOpenPlayer(video.url, video.tokenId)}>View</Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
          :
          <Container sx={{ py: 8 }} maxWidth="md">
            {/* Video display gallery */}
            <Grid container spacing={4}>
              {allVideo?.map((video) => (
                <Grid item key={video.url} xs={12} sm={6} md={4}>
                  <Card
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    <CardMedia
                      component="div"
                      sx={{
                        // 16:9
                        pt: '56.25%',
                      }}
                      image={video.content.cover}  // dynamically set the cover image
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {video.content.social?.Title || "No Title"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => handleOpenPlayer(video.url, video.tokenId)}>View</Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>}

        {/* Popup Modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Upload a Video
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                You can upload a video to our network.
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <Typography sx={{ mt: 1 }}>
                  Please add you video file here.
                </Typography>
                <MuiFileInput inputProps={{ accept: 'video/*' }} placeholder="Upload video file" value={video?.file} onChange={handleVideo} />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <Typography sx={{ mt: 1 }}>
                  Please add you video cover here.
                </Typography>
                <MuiFileInput inputProps={{ accept: 'image/jpeg' }} value={cover?.file} onChange={handleCover}
                  placeholder="Upload video cover image" />
              </Box>

              <Box component="form" onSubmit={handleUpload} noValidate sx={{ mt: 1, display: "contents" }}>
                <TextField sx={{ mt: 2 }} required name='indexer' defaultValue="https://indexer.vstack.one/" id="indexer" label="index server endpoint" variant="standard" />
                <TextField sx={{ mt: 2 }} required name='provider' defaultValue="https://provider.vstack.one/" id="provider" label="provider endpoint" variant="standard" />
                <TextField sx={{ mt: 2 }} required name='title' id="title" label="title" variant="standard" />
                <TextField sx={{ mt: 2 }} required name='summary' id="summary" label="summary" variant="standard" />

                <FormControl sx={{ mt: 2 }}>
                  <InputLabel id="demo-multiple-checkbox-label">Resolution</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={resolution}
                    onChange={handleResolutionChange}
                    input={<OutlinedInput label="Resolution" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {resolutions.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={resolution.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <LoadingButton type="submit" sx={{ mt: 2 }} loading={isLoading} variant="contained" loadingIndicator={<CircularProgress color="inherit" size={18} variant="determinate" value={uploadBuffer} />}>Upload</LoadingButton>
              </Box>
              <Button sx={{ mt: 2 }} onClick={checkUploadStatus} variant="contained">Video Transcoder Progress</Button>

              {serverStatus ? (
                // Render when ServerStatus has value
                <Grid spacing={1} sx={{ mt: 1 }} container>
                  <Grid xs item>
                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                  </Grid>
                </Grid>
              ) : (
                null
              )}
              {taskCompleted ? (
                // Render when task has Completed
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Your upload task is Completed
                </Typography>) : (null)}


            </Box>

          </Fade>
        </Modal>
      </main>
    </div>
  );

}