import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import { BsDiscord } from 'react-icons/bs';

export default function Footer() {
    return (
      <div className='content-overlay'>
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          © 2023 Vstack
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="#EEEEEE"
          component="p"
        >
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2}} >
          {/* GitHub */}
          <a style={{color:'#212121'}} href='https://github.com/vstack-project' target="_blank" rel="noreferrer"> <GitHubIcon sx={{ mx: 4, fontSize:'4vh' }}/></a>
          {/* Twitter */}
          <a style={{color:'#212121'}} href='https://twitter.com/vstack_one' target="_blank" rel="noreferrer"><TwitterIcon  sx={{ mx: 4, fontSize:'4vh' }} onClick={() =>{console.log("test")}}/></a>
          {/* Discord */}
          <a style={{color:'#212121'}} href='https://discord.gg/e8psxVNJ' target="_blank" rel="noreferrer"><BsDiscord style={{  marginLeft: '2rem', marginRight: '2rem', fontSize:'4vh' }}/></a>
        </Box>
      </Box>
      </div>
    )
}