import './App.css';

import Album from './components/navigation/album';
import Player from './components/player';
import NavBar from './components/navigation/navbar';
import Footer from './components/navigation/footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 

// default theme
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#9C27B0',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
});

function App() {
  return (
    <Router>
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
      <NavBar />
      {/* main content */}  
      <Switch>
          <Route exact path="/" component={Album} />
          <Route path="/player" component={Player} />
      </Switch>
      <Footer />
      </ThemeProvider>
    </div>
    </Router>
  );
}

export default App;