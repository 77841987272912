import '@rainbow-me/rainbowkit/styles.css';

import {
    getDefaultWallets, RainbowKitProvider, lightTheme, Chain} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { sepolia} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import App from './App';

const mumbai = {
    id: 80001,
    name: 'Mumbai Testnet',
    network: 'avalanche',
    iconUrl: 'https://example.com/icon.svg',
    iconBackground: '#fff',
    nativeCurrency: {
        decimals: 18,
        name: 'MATIC',
        symbol: 'MATIC',
    },
    rpcUrls: {
        public: { http: ['https://rpc-mumbai.maticvigil.com'] },
        default: { http: ['https://rpc-mumbai.maticvigil.com'] },
    },
    blockExplorers: {
        default: { name: 'polygonscan', url: 'https://mumbai.polygonscan.com/' },
        etherscan: { name: 'polygonscan', url: 'https://mumbai.polygonscan.com/' },
    },
    contracts: {
        multicall3: {
            address: '0xca11bde05977b3631167028862be2a173976ca11',
            blockCreated: 11907934,
        },
    },
    testnet: true,
};

const { chains, publicClient } = configureChains(
    [mumbai, sepolia],
    [
        alchemyProvider({ apiKey: "6EDoKYlQPVkeYGzQh79M4SUGnV2T3Hre" }),
        publicProvider()
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'VStack',
    projectId: '46e21298267616c47f8df51dc89ae158',
    chains
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

export default function Config() {
    return (
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}
                theme={lightTheme({
                    accentColor: '#9C27B0',
                    accentColorForeground: 'white',
                    borderRadius: 'small',
                    fontStack: 'system',
                    overlayBlur: 'small',
                })}
            >
                <App />
            </RainbowKitProvider>
        </WagmiConfig>
    )
}
