import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Container from '@mui/material/Container';

export default function Player() {
  const url = 'https://indexer.vstack.one/api/v1/media/' + window.location.pathname.split('/')[2] + '?route=auto';
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false); // keep track of whether video has loaded
  const [videoSocial, setVideoSocial] = useState(null);
  const [videoQuality, setVideoQuality] = useState(null); // keep track of available video quality levels
  const [selectedQuality, setSelectedQuality] = useState(null);
  const [videoTokenId, setVideoTokenId] = useState(null);
  const hlsRef = useRef(new Hls());
  // Extract the tokenId parameter



  const fetchData = () => {
    fetch(url, {
      headers: {
        'accept': 'application/json',
      },
    })
      .then(response => response.text())
      .then(data => {
        const lines = data.split('\n');
        const parsedData = {};

        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].trim();

          if (line.startsWith('#EXT-X-VERSION')) {
            parsedData.version = line.split(':')[1];
          } else if (line.startsWith('#MEDIA-TOTAL-SIZE')) {
            parsedData.totalSize = line.split(':')[1];
          } else if (line.startsWith('#MEDIA-IDENTIFIER')) {
            parsedData.identifier = line.split(':')[1];
          } else if (line.startsWith('#MEDIA-COVER')) {
            parsedData.cover = "https:" + line.split(':')[2];
          } else if (line.startsWith('#MEDIA-SOURCE')) {
            const encodedSource = line.split(':')[1];
            parsedData.source = JSON.parse(atob(encodedSource));
          } else if (line.startsWith('#MEDIA-SOCIAL')) {
            const encodedSource = line.split(':')[1];
            parsedData.social = JSON.parse(atob(encodedSource));
          } else if (line.startsWith('#EXT-X-STREAM-INF')) {
            const streamInfo = line.split(':')[1];
            const streamAttributes = streamInfo.split(',');
            const bandwidth = streamAttributes.find(attr => attr.startsWith('BANDWIDTH=')).split('=')[1];
            const resolution = streamAttributes.find(attr => attr.startsWith('RESOLUTION=')).split('=')[1];
            const frameRate = streamAttributes.find(attr => attr.startsWith('FRAME-RATE=')).split('=')[1];
            const url = lines[i + 1];

            parsedData.streams = parsedData.streams || [];
            parsedData.streams.push({
              bandwidth,
              resolution,
              frameRate,
              url
            });
          }
        }

        console.log(parsedData);
        setVideoSocial(parsedData.social);
        // handle parsed data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    const video = videoRef.current;
    const parsedUrl = new URL(window.location.href);
    const tokenId = parsedUrl.searchParams.get('tokenId');
    setVideoTokenId(tokenId);
    fetchData();
    if (video) {
      if (Hls.isSupported()) {
        const hls = hlsRef.current;
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          setVideoLoaded(true); // update video loaded state
          const levels = hls.levels.map((level, index) => ({
            value: index,
            label: `${level.height}p`,
          }));
          setVideoQuality(levels); // update available video quality levels
          setSelectedQuality(levels[0].value); // set default video quality
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url;
        video.addEventListener('loadedmetadata', () => {
          setVideoLoaded(true); // update video loaded state
        });
      }
    }
    return () => {
      if (hlsRef.current && videoLoaded) {
        hlsRef.current.destroy();
      }
    };
  }, [url, videoLoaded]);

  const handleQualityChange = (event) => {
    const newQuality = event.target.value;
    setSelectedQuality(newQuality);
    const hls = hlsRef.current;
    if (hls) {
      hls.currentLevel = newQuality;
      console.log(hlsRef.current.currentLevel);
    }
  };

  const handleFastForward = () => {
    const video = videoRef.current;
    video.currentTime += 5; // Increase the current time by 5 seconds (adjust as needed)
  };

  const handleFastBackward = () => {
    const video = videoRef.current;
    video.currentTime -= 5; // Decrease the current time by 5 seconds (adjust as needed)
  };

  const handleOpenPolyScan = () => {
    window.open('https://mumbai.polygonscan.com/token/0x9f81e1a0a6545cf7d32ac3717d2e59c94e86ea1d' + '?a=' + videoTokenId, '_blank');
  }
  return (
<div className="content-overlay">
  {/* Title */}
  <Typography variant="h5" align="center" color="#EEEEEE" sx={{ mt: { xs: 4, sm: 10 }, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
    {videoSocial?.Title}
  </Typography>

  {/* Video Player */}
  <div style={{ width: '100%' }}>
    <video ref={videoRef} controls style={{ width: '65%' }} />
  </div>

  <Box sx={{ mt: 3, flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'center' }}>
    {/* Buttons */}
    <Button variant="contained" onClick={handleOpenPolyScan} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>Check NFT</Button>
    <Button variant="contained" onClick={handleFastBackward} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>Backward</Button>
    <Button variant="contained" onClick={handleFastForward} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>Forward</Button>

    {/* Select video quality */}
    <TextField
      id="outlined-select-quality"
      select
      value={selectedQuality}
      onChange={handleQualityChange}
      variant="outlined"
      size="small"
      sx={{ mb: { xs: 2, sm: 0 }, height: 36, width: { xs: '100%', sm: 'auto' } }}
    >
      {videoQuality?.map((element) => (
        <MenuItem key={element.value} value={element.value}>
          {element.label}
        </MenuItem>
      ))}
    </TextField>

    {/* Video description */}
    <Typography variant="h6" align="center" color="#EEEEEE" sx={{ mt: 3, fontSize: { xs: '1rem', sm: '1.2rem' } }}>
      {videoSocial?.Summary}
    </Typography>
  </Box>
</div>
  );
}
